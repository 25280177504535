@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
}

:root {
  /* colors */
  --primary-color: #090B17;
  --secondary-color: #c98313;
  --logo-red: #6C0404;
  --color-white: #ffffff;
  /* font size */
  --font-size-sm-para: 0.9vw;
  --font-size-lg-para: 1vw;
  --font-size-lg-para-1: 1.1vw;
  --font-size-lg-para-2: 1.2vw;
  --font-size-lg-para-3: 1.3vw;
  --font-size-lg-para-4: 1.4vw;
  --font-size-lg-h1: 3vw;
  --font-size-lg-h2: 2vw;
  --font-size-lg-h3: 1.5vw;
  --font-size-lg-h4: 1.6vw;
  --font-size-lg-h5: 1.7vw;
  --font-size-lg-h6: 1.8vw;
  --max-width-100: 100%;
  --height-auto: auto;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  --border-0: none;
  --border-1: 1px;
  --border-2: 2px;
  --border-3: 3px;
  --border-4: 4px;
  --border-5: 5px;
  --radius-0: 0;
  --radius-5: 5px;
  --radius-10: 10px;
  --radius-20: 20px;
  --radius-30: 30px;
  --radius-40: 40px;
  --radius-50: 50px;
  --radius-60: 60px;
}

@media screen and (max-width:600px) {
  :root {
    /* colors */
    --primary-color: #090B17;
    --secondary-color: #c98313;
    --logo-red: #6C0404;
    --color-white: #ffffff;
    /* font size */
    --font-size-sm-para: 0.9vw;
    --font-size-lg-para: 3.5vw !important;
    --font-size-lg-para-1: 1.1vw;
    --font-size-lg-para-2: 1.2vw;
    --font-size-lg-para-3: 1.3vw;
    --font-size-lg-para-4: 1.4vw;
    --font-size-lg-h1: 4.9vw;
    --font-size-lg-h2: 3.5vw;
    --font-size-lg-h3: 4.6vw;
    --font-size-lg-h4: 4.7vw;
    --font-size-lg-h5: 4.8vw;
    --font-size-lg-h6: 4.9vw;
    --max-width-100: 100%;
    --height-auto: auto;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;
    --font-weight-900: 900;
    --border-0: none;
    --border-1: 1px;
    --border-2: 2px;
    --border-3: 3px;
    --border-4: 4px;
    --border-5: 5px;
    --radius-0: 0;
    --radius-5: 5px;
    --radius-10: 10px;
    --radius-20: 20px;
    --radius-30: 30px;
    --radius-40: 40px;
    --radius-50: 50px;
    --radius-60: 60px;
  }
}

.plus-icon {
  font-size: 1vw !important;
}

.responsive-img {
  max-width: var(--max-width-100);
  height: var(--height-auto);
}

.list-type-none {
  list-style-type: none !important;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.cursor-pointer {
  cursor: pointer;
}

.gap-1 {
  gap: 1%;
}

.gap-2 {
  gap: 2%;
}

.gap-3 {
  gap: 3%;
}

.gap-4 {
  gap: 4%;
}

.gap-5 {
  gap: 5%;
}

.navbar-header {
  background-color: var(--secondary-color);
  height: 6vh;
  border-bottom-right-radius: var(--radius-50);
  width: 98%;
  padding-left: 7%;
  padding-right: 6%;
}

.navbar-header .navbar-header-para {
  font-size: var(--font-size-lg-para);
  color: white;
}

.navbar-header2 {
  background-color: var(--secondary-color);
  height: 13vh;
}

.navbar-header2 .navbar-header-menu {
  background-color: white;
  height: 13vh;
  margin-left: 1.2%;
  border-top-left-radius: var(--radius-60);
  padding-left: 5%;
  padding-right: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-header-menu .navbar-header-menu-img img {
  width: 18%;
}

.navbar-header-menu .navbar-header-menu-img h1 {
  font-size: var(--font-size-lg-h3);
}

.navbar-header-menu .navbar-header-menu-img .brand-tagline {
  font-size: var(--font-size-lg-para) !important;
  color: var(--secondary-color);
}

.navbar-header-menu .navbar-header-menu-list ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15%;
  padding-right: 200px;
  font-size: var(--font-size-lg-para-1);
  font-weight: var(--font-weight-500);
  color: var(--primary-color);
}

.navbar-header2-btn button {
  background-color: var(--secondary-color);
  color: var(--color-white);
  padding: 10px 20px;
  font-size: var(--font-size-lg-para);
  font-weight: var(--font-weight-600);
  border: var(--border-0);
  border-radius: var(--radius-5);
}

.navbar-header2-btn button:hover {
  background-color: #b37512;
}

.hero-bg {
  background-image: linear-gradient(#000000c5, #000000c5), url("./Assets/Images/JayMamta-Wallpaper/jaymamta-wallpaper2.webp");
  background-position: center;
  background-size: cover;
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
}

.hero-bg img {
  width: 15%;
}

.hero-bg .hero-bg-h1 {
  font-size: var(--font-size-lg-h2);
  text-align: center;
  color: var(--secondary-color);
}

.hero-bg .hero-bg-h2 {
  font-size: var(--font-size-lg-h1);
  text-align: center;
}

@media screen and (min-width:600px) {
  .hide-desktop {
    display: none;
  }
}

@media screen and (max-width:600px) {
  .hide-mobile {
    display: none;
  }

  .plus-icon {
    font-size: var(--font-size-lg-para) !important;
  }

  .hero-bg img {
    width: 20%;
  }

  .hero-bg {
    height: 50vh;
  }
}